




































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import Clipboard from 'clipboard';
import { trackEvent } from '@/api/home';

@Component({
    name: 'HomePage',
    components: {
    }
})
export default class HomePage extends Vue {
    get locale() {
        return AppModule.locale;
    }

    $i18n: any;
    dialogVisible = false

    copyLink = 'https://www.gogohelpers.com/en/login'

    recruitmentList: any = [
        {
            label: this.$t('home.marketJob'),
            img: require('@/assets/images/recruitment01@2x.png')
        },
        {
            label: this.$t('home.sourcing'),
            img: require('@/assets/images/recruitment02@2x.png')
        },
        {
            label: this.$t('home.screening'),
            img: require('@/assets/images/recruitment03@2x.png')
        },
        {
            label: this.$t('home.employerInterviews'),
            img: require('@/assets/images/recruitment04@2x.png')
        },
        {
            label: this.$t('home.confirmJobOrder'),
            img: require('@/assets/images/recruitment05@2x.png')
        },
        {
            label: this.$t('home.signContract'),
            img: require('@/assets/images/recruitment06@2x.png')
        }
    ]

    deploymentList: any = [
        {
            label: this.$t('home.medicalCheck'),
            img: require('@/assets/images/deployment1@2x.png')
        },
        {
            label: this.$t('home.documentation'),
            img: require('@/assets/images/documentation.png')
        },
        {
            label: this.$t('home.deployToOversea'),
            img: require('@/assets/images/deployment6@2x.png')
        },
    ]

    ourValueList: any = [
        {
            label: this.$t('home.integrity'),
            img: require('@/assets/images/Integrity@2x.png')
        },
        {
            label: this.$t('home.compassion'),
            img: require('@/assets/images/Compassion@2x.png')
        },
        {
            label: this.$t('home.accountability'),
            img: require('@/assets/images/Accountability@2x.png')
        },
        {
            label: this.$t('home.customerCommitment'),
            img: require('@/assets/images/CustomerCommitment@2x.png')
        }
    ]

    trainingImgList: any = [
        {
            img: 'https://file.pjinnovation.com/peanut/prod/landing-id-web/training3.png'
        },
        {
            img: 'https://file.pjinnovation.com/peanut/prod/landing-id-web/training4.png'
        },
        {
            img: 'https://file.pjinnovation.com/peanut/prod/landing-id-web/training5.png'
        },
        {
            img: 'https://file.pjinnovation.com/peanut/prod/landing-id-web/training6.png'
        },
        {
            img: 'https://file.pjinnovation.com/peanut/prod/landing-id-web/training7.png'
        },
        {
            img: 'https://file.pjinnovation.com/peanut/prod/landing-id-web/training8.png'
        }
    ]

    bannerList: any = [
        require('@/assets/images/part-one.png'),
    ]

    @Watch('locale')
    languageImgWatch() {
        if (this.locale === 'id') {
            this.trainingImgList = [
                {
                    img: "https://file.pjinnovation.com/peanut/prod/landing-id-web/id/training3.png"
                },
                {
                    img: "https://file.pjinnovation.com/peanut/prod/landing-id-web/id/training4.png"
                },
                {
                    img: "https://file.pjinnovation.com/peanut/prod/landing-id-web/id/training5.png"
                },
                {
                    img: "https://file.pjinnovation.com/peanut/prod/landing-id-web/id/training6.png"
                },
                {
                    img: "https://file.pjinnovation.com/peanut/prod/landing-id-web/id/training7.png"
                },
                {
                    img: "https://file.pjinnovation.com/peanut/prod/landing-id-web/id/training8.png"
                },
            ]
        } else {
            this.trainingImgList = [
                {
                    img: "https://file.pjinnovation.com/peanut/prod/landing-id-web/training3.png"
                },
                {
                    img: "https://file.pjinnovation.com/peanut/prod/landing-id-web/training4.png"
                },
                {
                    img: "https://file.pjinnovation.com/peanut/prod/landing-id-web/training5.png"
                },
                {
                    img: "https://file.pjinnovation.com/peanut/prod/landing-id-web/training6.png"
                },
                {
                    img: "https://file.pjinnovation.com/peanut/prod/landing-id-web/training7.png"
                },
                {
                    img: "https://file.pjinnovation.com/peanut/prod/landing-id-web/training8.png"
                },
            ]
        }
        this.recruitmentList = [
            {
                label: this.$t('home.marketJob'),
                img: require('@/assets/images/recruitment01@2x.png')
            },
            {
                label: this.$t('home.sourcing'),
                img: require('@/assets/images/recruitment02@2x.png')
            },
            {
                label: this.$t('home.screening'),
                img: require('@/assets/images/recruitment03@2x.png')
            },
            {
                label: this.$t('home.employerInterviews'),
                img: require('@/assets/images/recruitment04@2x.png')
            },
            {
                label: this.$t('home.confirmJobOrder'),
                img: require('@/assets/images/recruitment05@2x.png')
            },
            {
                label: this.$t('home.signContract'),
                img: require('@/assets/images/recruitment06@2x.png')
            }
        ]
        this.deploymentList = [
            {
                label: this.$t('home.medicalCheck'),
                img: require('@/assets/images/deployment1@2x.png')
            },
            {
                label: this.$t('home.documentation'),
                img: require('@/assets/images/documentation.png')
            },
            {
                label: this.$t('home.deployToOversea'),
                img: require('@/assets/images/deployment6@2x.png')
            },
        ]
        this.ourValueList = [
            {
                label: this.$t('home.integrity'),
                img: require('@/assets/images/Integrity@2x.png')
            },
            {
                label: this.$t('home.compassion'),
                img: require('@/assets/images/Compassion@2x.png')
            },
            {
                label: this.$t('home.accountability'),
                img: require('@/assets/images/Accountability@2x.png')
            },
            {
                label: this.$t('home.customerCommitment'),
                img: require('@/assets/images/CustomerCommitment@2x.png')
            }
        ]
    }

    handleApply() {
        this.dialogVisible = true;
    }

    copy() {
        let clipboard = new Clipboard('.copy');
        clipboard.on('success', (e)=> {
            this.$message.success((this.$t('home.copySuccess')) as any);
            e.clearSelection();
            this.dialogVisible = false;
            clipboard.destroy();
        });
    }

    clickFacebook() {
        window.open('https://www.facebook.com/zealcare02')
    }

    clickWhatsApp() {
        window.open('https://wa.me/6281398099559')
        trackEvent({
            eventType: 304,
            url: window.location.href,
            websiteName: 'ID',
            tool: 'PC',
            btnName: 'Contact Our Recruiter',
            btnTargetValue: '6281398099559'
        })
    }
    mounted() {
        if (process.env.NODE_ENV != 'production') {
            this.copyLink = 'http://gogohelpers.test.sesameitech.com/en/login';
        }
        if (this.locale === 'id') {
            this.trainingImgList = [
                {
                    img: "https://file.pjinnovation.com/peanut/prod/landing-id-web/id/training3.png"
                },
                {
                    img: "https://file.pjinnovation.com/peanut/prod/landing-id-web/id/training4.png"
                },
                {
                    img: "https://file.pjinnovation.com/peanut/prod/landing-id-web/id/training5.png"
                },
                {
                    img: "https://file.pjinnovation.com/peanut/prod/landing-id-web/id/training6.png"
                },
                {
                    img: "https://file.pjinnovation.com/peanut/prod/landing-id-web/id/training7.png"
                },
                {
                    img: "https://file.pjinnovation.com/peanut/prod/landing-id-web/id/training8.png"
                },
            ]
        }
    }
}
